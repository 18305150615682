import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link, Container, Typography, Box, TextField, Button, useTheme} from '@mui/material';

const ContactPage = () => {
    const theme = useTheme();
    return (
        <Layout>
            <Seo title="Contact" noindex />
            <Container maxWidth="lg" sx={{ pt: 5, pb: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, p: 4 }}>
                        <Typography component="h1" variant="h2" gutterBottom>Connect With Us</Typography>
                        <Typography variant="body1" sx={{ mb: 4 }}>Want to say hello, send us feedback, or just join the conversation? We’d love to hear from you!</Typography>
                        <Typography variant="body1">We do our best to reply to all messages. The quickest way to get a response from us is on social media. You can also fill in the form or drop us an email.</Typography>
                        <Box sx={{ display: 'flex', svg: { mr: 2 }, my: 2 }}>
                            <Link target="_blank" href="https://www.facebook.com/Radiant-Family-Home-105171228666939"><FontAwesomeIcon icon={faFacebook} size="lg" /></Link>
                            <Link target="_blank" href="https://www.twitter.com/rfh_social"><FontAwesomeIcon icon={faTwitter} size="lg" /></Link>
                        </Box>
                        <Typography variant="body1">hello &#123; at &#125; radiantfamilyhome.com</Typography>
                    </Box>
                    <Box component="form" name="Contact Form" data-netlify="true" netlify-honeypot="phone" action="/thank-you" sx={{ width: { xs: '100%', md: '50%' }, pt: 10 }}>
                        <input type="hidden" name="form-name" value="Contact Form" />
                        <TextField label="Name" type="text" color="primary" variant="outlined" name="name" fullWidth />
                        <TextField label="Email" type="email" color="primary" variant="outlined" name="email" fullWidth />
                        <TextField label="Phone" type="text" color="primary" variant="outlined" name="phone" fullWidth sx={{display: 'none'}} />
                        <TextField label="Message" type="text" color="primary" variant="outlined" name="message" multiline rows={4} fullWidth />
                        <Button type="submit" variant="contained" size="large" fullWidth>Send Message</Button>
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}

export default ContactPage